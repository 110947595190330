import './App.css';
import '@fontsource/inter';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppLogin from './pages/login';
import Layout from './layouts/Layout';
import { ToastContainer } from 'react-toastify';
import Home from './pages/home';
import { AuthContextProvider } from './context/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './components/PrivateRoute';
import TeamExample from './pages/landing';
import Album from './components/Album';
import Pricing from './components/Pricing';
import Settings from './pages/settings';
import Dashboard from './pages/dashboard/dashboard';
import AddMember from './pages/member/addMember';
import AddMemberOtherCountry from './pages/member/addMemberOtherCountry';
import MemberList from './pages/member/memberList';
import LevelView from './pages/member/levelView';
import DirectMemberList from './pages/member/directMemberList';
import LiveBalanceRequest from './pages/investmentPayment/liveBalanceRequest';
import Trcdeposit from './pages/trcdepo/Trcdeposit';
import About from './pages/About';
import Calls from './pages/Calls';
import Contact from './pages/Contact';
import Education from './pages/Education';
import MyProfile from './pages/MyAccounts/MyProfile';
import SupportSystem from './pages/support/SupportSystem';
import LiveBalanceRequestList from './pages/investmentPayment/LiveBalanceRequestList';
import CriptoAddress from './pages/MyAccounts/CriptoAddress';
import ModifiedPassword from './pages/MyAccounts/ModifiedPassword';
import TrcWithdrawal from './pages/withdrawal/TrcWithdrawal';
import KycVerfication from './pages/MyAccounts/KycVerfication';
import MainSupport from './pages/support/MainSupport';
import AppSignUp from './pages/AppSignUp';
import OldSettleMent from './pages/oldsettlement/OldSettleMent';
import Message from './pages/message/Message';
import OldSettleMentView from './pages/oldsettlement/OldSettleMentView';
import Wallet from './pages/wallet/Wallet';
import InvestmentReport from './pages/report/InvestmentReport';
import WithdrawalReport from './pages/report/WithdrawalReport';
import InvestmentDistribution from './pages/Distribution/InvestmentDistribution';
import InvestmentDistributionView from './pages/Distribution/InvestmentDistributionView';
import OldSettleApproveList from './pages/oldsettlement/OldSettleApproveList';
import GenealogyTree from './pages/member/GenealogyTree';
import { GenealogyTreeProvider } from './context/TreeContext';
import Payment from './pages/investmentPayment/Payment';
import CreatePayment from './pages/investmentPayment/CreatePayment';
import InvestmentPaymentReport from './pages/investmentPayment/InvestmentPaymentReport';
import OldSettlePayment from './pages/oldsettlement/OldSettlePayment';
import OldSettleMentReport from './pages/oldsettlement/OldSettleMentReport';
import OldSettlePaymentUpdate from './pages/oldsettlement/OldSettlePaymentUpdate';
import KycVerificationList from './pages/MyAccounts/KycVerificationList';
import InvestmentDistributionReport from './pages/Distribution/InvestmentDistributionReport';
import FundWallet from './pages/wallet/FundWallet';
import PrincipleWithdrawal from './pages/withdrawal/PrincipleWithdrawal';
import WithdrawalRequest from './pages/withdrawal/WithdrawalRequest';

function App() {
  return (
    <Router>
      <AuthContextProvider>
        <GenealogyTreeProvider>
          <ToastContainer />
          <Routes>
            <Route path="/" element={<TeamExample />} />
            <Route path="/about" element={<About />} />
            <Route path="/calls" element={<Calls />} />
            <Route path="/education" element={<Education />} />
            <Route path="/contact" element={<Contact />} />
            {/* <Route path="/album" element={<Album />} /> */}
            {/* <Route path="/pricing" element={<Pricing />} /> */}
            <Route path="/login" element={<AppLogin />} />
            <Route path="/signup" element={<AppSignUp />} />
            {/* <Route path="" element={} /> */}
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/home" element={<Home />} />
              <Route path="/message" element={<Message />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/addmember" element={<AddMember />} />
              <Route path="/myprofile" element={<MyProfile />} />
              <Route path="/mycripto" element={<CriptoAddress />} />
              <Route path="/password" element={<ModifiedPassword />} />
              <Route path="/kyc" element={<KycVerfication />} />
              <Route
                path="/kycverifications"
                element={<KycVerificationList />}
              />
              <Route
                path="/addmemberothercountry"
                element={<AddMemberOtherCountry />}
              />
              <Route path="/trcwithdrawal" element={<TrcWithdrawal />} />
              <Route
                path="/withdrawalapproval"
                element={<WithdrawalRequest />}
              />
              <Route
                path="/principalwithdrawal"
                element={<PrincipleWithdrawal />}
              />
              <Route path="/fundwallet" element={<FundWallet />} />
              <Route path="/trcdeposit" element={<Trcdeposit />} />
              <Route path="/memberlist" element={<MemberList />} />
              <Route path="/directmemberlist" element={<DirectMemberList />} />
              <Route path="/genealogytree" element={<GenealogyTree />} />
              <Route path="/directmemberlist" element={<DirectMemberList />} />
              <Route path="/levelview" element={<LevelView />} />
              <Route path="/supportsystem" element={<SupportSystem />} />
              <Route path="/oldsettlement" element={<OldSettleMent />} />
              <Route path="/oldsettlement/:id" element={<OldSettleMent />} />
              <Route
                path="/oldsettlementview"
                element={<OldSettleMentView />}
              />
              <Route
                path="/oldsettlementreport"
                element={<OldSettleMentReport />}
              />
              <Route
                path="/oldsettlementpayment"
                element={<OldSettlePayment />}
              />
              <Route
                path="/oldsettlementpaymentupdate"
                element={<OldSettlePaymentUpdate />}
              />
              <Route
                path="/Approval-List-Of-Old-Settlememt"
                element={<OldSettleApproveList />}
              />
              <Route
                path="/investmentdistribution"
                element={<InvestmentDistribution />}
              />
              <Route
                path="/investmentdistribution/:id?"
                element={<InvestmentDistribution />}
              />
              <Route
                path="/investmentdistributionview"
                element={<InvestmentDistributionView />}
              />
              <Route
                path="/investmentdisreport"
                element={<InvestmentDistributionReport />}
              />
              <Route path="/support" element={<MainSupport />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/mywallet" element={<Wallet />} />
              <Route path="/mywallet" element={<Wallet />} />
              <Route path="/investmentpayment" element={<Payment />} />
              <Route
                path="/investmentpayment/:id"
                element={<CreatePayment />}
              />
              {/* <Route path="/investmentreport" element={<InvestmentReport />} /> */}
              <Route
                path="/investmentreport"
                element={<InvestmentPaymentReport />}
              />
              <Route path="/investmentreports" element={<InvestmentReport />} />
              <Route path="/withdrawalreport" element={<WithdrawalReport />} />
              <Route
                path="/live-balance-request"
                element={<LiveBalanceRequest />}
              />
              <Route
                path="/live-balance-request-list"
                element={<LiveBalanceRequestList />}
              />
            </Route>
          </Routes>
        </GenealogyTreeProvider>
      </AuthContextProvider>
    </Router>
  );
}

export default App;
