import React, { useState } from "react";
import {
  Box,
  Typography,
  Sheet,
  FormControl,
  FormLabel,
  Input,
  Button,
} from "@mui/joy";
import { db } from "../../firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import front1 from "../../assets/IMG-20241124-WA0018.jpg";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { useEffect } from "react";
import { updateDoc } from "firebase/firestore";

function InvestmentDistribution() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [selectedModalOption, setSelectedModalOption] = useState("");
  const [formData, setFormData] = useState({
    investmentAccount: "",
    asonvalue: "",
    email: "",
    password: "",
    mt5Id: "",
    mt5Password: "",
    investmentSegment: "",
  });

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const docRef = doc(db, "investmentDistribution", id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setFormData(docSnap.data());
          } else {
            toast.error("Document not found.");
          }
        } catch (error) {
          console.error("Error fetching document: ", error);
          toast.error("Failed to load data.");
        }
      }
    };

    fetchData();
  }, [id]);

  const handleSlotChange = (slot) => {
    setSelectedModalOption(slot);
    setFormData((prev) => ({
      ...prev,
      investmentSegment: slot, // Assuming "investmentSegment" is the key in the formData
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // const handleSubmit = async () => {
  //   try {
  //     const dataToSave = {
  //       ...formData,
  //       createdAt: Timestamp.now(),
  //       createdBy: user.uid,
  //     };

  //     // Add document to Firestore
  //     const docRef = await addDoc(
  //       collection(db, 'investmentDistribution'),
  //       dataToSave
  //     );
  //     console.log('Document written with ID: ', docRef.id);
  //     toast.success('Data submitted successfully!');

  //     setFormData({
  //       investmentAccount: '',
  //       email: '',
  //       mt5Password: '',
  //     });
  //     setSelectedModalOption('');

  //     navigate('/investmentdistributionview');
  //   } catch (error) {
  //     console.error('Error adding document: ', error);
  //     alert('Failed to submit data!');
  //   }
  // };

  const handleSubmit = async () => {
    try {
      const dataToSave = {
        ...formData,
        createdAt: Timestamp.now(),
        createdBy: user.uid,
      };

      if (id) {
        // Update existing document
        const docRef = doc(db, "investmentDistribution", id);
        await updateDoc(docRef, dataToSave);
        toast.success("Data updated successfully!");
      } else {
        // Add new document
        const docRef = await addDoc(
          collection(db, "investmentDistribution"),
          dataToSave
        );
        toast.success("Data submitted successfully!");
      }

      navigate("/investmentdistributionview");
    } catch (error) {
      console.error("Error saving data: ", error);
      toast.error("Failed to submit data!");
    }
  };

  return (
    <>
      <Box
        sx={{
          padding: 2,
          minHeight: "100vh",
          // backgroundColor: '#40474F',
        }}
      >
        <img
          src={front1}
          alt="Background"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        />
        <Typography
          level="h2"
          style={{
            color: "#FFFFFF",
            marginTop: "35px",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
          }}
          fontWeight="lg"
        >
          Investment Distribution
        </Typography>
        <Sheet
          variant="soft"
          sx={{
            padding: 3,
            borderRadius: "sm",
            maxWidth: 1500,
            marginTop: 2,
            backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white
            backdropFilter: "blur(10px)", // Optional: Adds a blur effect for the content behind
            boxShadow: "none",
          }}
          bgcolor="neutral.softHoverBg"
        >
          <Button
            onClick={() => navigate("/investmentdistributionview")}
            variant="soft"
            sx={{
              // marginTop: 3,
              backgroundColor: "red",
              color: "#FFFFFF",
            }}
          >
            <ReplyAllIcon />
          </Button>
          {/* Dropdown */}
          <FormControl sx={{ marginBottom: 2 }}>
            <FormLabel sx={{ fontSize: "22px", marginTop: 2 }}>
              Investment segment
            </FormLabel>
            <select
              id="timeSlot"
              className="px-2 py-2 border rounded bg-transparent border-gray-600"
              value={formData.investmentSegment || ""} // Bind to formData
              onChange={(e) => handleSlotChange(e.target.value)}
              style={{ backgroundColor: "#1a2a339c", color: "white" }}
            >
              <option value="" disabled>
                Select Your Share
              </option>
              <option value="Crypto">Crypto</option>
              <option value="FCX">FCX</option>
              <option value="ROX">ROX</option>
              <option value="Multi_Bank">Multi Bank</option>
              <option value="Indian_Market">Indian Market</option>
            </select>
          </FormControl>

          {/* Input Fields Side by Side */}
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", marginTop: 2 }}>
            <FormControl sx={{ flex: 1, minWidth: "200px" }}>
              <FormLabel>Email</FormLabel>
              <Input
                placeholder="Enter your email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                style={{ backgroundColor: "#1a2a339c", color: "white" }}
              />
            </FormControl>
            <FormControl sx={{ flex: 1, minWidth: "200px" }}>
              <FormLabel>Password</FormLabel>
              <Input
                placeholder="Enter your password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleInputChange}
                style={{ backgroundColor: "#1a2a339c", color: "white" }}
              />
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", marginTop: 2 }}>
            <FormControl sx={{ flex: 1, minWidth: "200px" }}>
              <FormLabel>MT5 Id</FormLabel>
              <Input
                placeholder="Enter your MT5 Id"
                name="mt5Id"
                type="text"
                value={formData.mt5Id}
                onChange={handleInputChange}
                style={{ backgroundColor: "#1a2a339c", color: "white" }}
              />
            </FormControl>
            <FormControl sx={{ flex: 1, minWidth: "200px" }}>
              <FormLabel>MT5 Password</FormLabel>
              <Input
                placeholder="Enter your MT5 password"
                name="mt5Password"
                type="password"
                value={formData.mt5Password}
                onChange={handleInputChange}
                style={{ backgroundColor: "#1a2a339c", color: "white" }}
              />
            </FormControl>
            <FormControl sx={{ flex: 1, minWidth: "200px" }}>
              <FormLabel>Investment Amount</FormLabel>
              <Input
                placeholder="Enter your investment amount"
                name="investmentAccount"
                value={formData.investmentAccount}
                onChange={handleInputChange}
                style={{ backgroundColor: "#1a2a339c", color: "white" }}
              />
            </FormControl>
            <FormControl sx={{ flex: 1, minWidth: "200px" }}>
              <FormLabel>As on value</FormLabel>
              <Input
                placeholder="Enter your As on valuet"
                name="asonvalue"
                value={formData.asonvalue}
                onChange={handleInputChange}
                style={{ backgroundColor: "#1a2a339c", color: "white" }}
              />
            </FormControl>
          </Box>

          {/* Submit Button */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              onClick={() => navigate("/investmentdistributionview")}
              variant="soft"
              sx={{
                marginTop: 3,
                backgroundColor: "red",
                color: "#FFFFFF",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="soft"
              sx={{
                marginTop: 3,
                backgroundColor: "#1976d2",
                color: "#FFFFFF",
              }}
            >
              {id ? "Update" : "Submit"}
            </Button>
          </div>
        </Sheet>
      </Box>
    </>
  );
}

export default InvestmentDistribution;
