import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Sheet,
  Button,
  Modal,
  Table,
  FormControl,
  FormLabel,
  Input,
} from '@mui/joy';
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  where,
  query,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import { CssVarsProvider } from '@mui/joy/styles';
import framesxTheme from '../../theme';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase';
import front1 from '../../assets/IMG-20241124-WA0018.jpg';
import { useNavigate } from 'react-router-dom';

const Payment = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const userId = user.uid;
  const [members, setMembers] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    fromDate: '',
    toDate: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [payableAmount, setPayableAmount] = useState(null); // To store the calculated amount
  const [calculationModal, setCalculationModal] = useState(false); // Modal state for calculation result
  const [selectedMember, setSelectedMember] = useState(null); // Selected member for calculation
  const [paymentType, setPaymentType] = useState(''); // For payment type dropdown
  const [referenceNo, setReferenceNo] = useState('');
  const [kycData, setKycData] = useState([]);

  console.log('nnn', selectedMember);

  // useEffect(() => {
  //   const fetchMembers = async () => {
  //     try {
  //       const q = query(
  //         collection(db, 'liveAccountRequests'),
  //         where('status', '==', 'Accepted')
  //       );
  //       const querySnapshot = await getDocs(q);
  //       const membersList = querySnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //       setMembers(membersList);
  //     } catch (error) {
  //       console.error('Error fetching members from Firebase:', error);
  //       toast.error('Error fetching members from Firebase!');
  //     }
  //   };

  //   fetchMembers();
  // }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch liveAccountRequests data
        const liveAccountRequestsQuery = query(
          collection(db, 'liveAccountRequests'),
          where('status', '==', 'Accepted')
        );
        const liveAccountRequestsSnapshot = await getDocs(
          liveAccountRequestsQuery
        );
        const liveAccountRequests = liveAccountRequestsSnapshot.docs.map(
          (doc) => ({
            id: doc.id,
            ...doc.data(),
          })
        );

        // Fetch kycVerifications data
        const kycVerificationsQuery = query(collection(db, 'kycVerifications'));
        const kycVerificationsSnapshot = await getDocs(kycVerificationsQuery);
        const kycVerifications = kycVerificationsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMembers(liveAccountRequests);
        setKycData(kycVerifications);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error fetching data!');
      }
    };

    fetchData();
  }, [userId]);

  const isPayNowEnabled = (createdUserId) => {
    const kycMatch = kycData.find(
      (kyc) => kyc.userId === createdUserId && kyc.status === 'Accepted'
    );
    return !!kycMatch;
  };

  // Calculate Payable Amount
  const calculatePayableAmount = (member) => {
    const { investmentAmount, rateOfInterest, investmentdate } = member;

    const investmentAmountNum = parseFloat(investmentAmount);
    const rateOfInterestNum = parseFloat(rateOfInterest);

    const fromDate = new Date(formData.fromDate);
    const toDate = new Date(formData.toDate);
    const investmentDateObj = new Date(investmentdate); // Convert investment date to Date object

    // Validate dates
    if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
      toast.error('Please select valid From and To dates!');
      return;
    }

    // Check if investment date and fromDate are in the same month and year
    const isSameMonthAndYear =
      fromDate.getFullYear() === investmentDateObj.getFullYear() &&
      fromDate.getMonth() === investmentDateObj.getMonth();

    // Calculate timeDifference based on the condition
    let timeDifference;
    if (isSameMonthAndYear) {
      timeDifference = fromDate.getTime() - investmentDateObj.getTime(); // Difference from investment date
    } else {
      timeDifference = fromDate.getTime() - toDate.getTime(); // Default difference
    }

    const selectedDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) + 1; // Convert to days and add 1 to include both start and end dates
    const currentDate = new Date();
    const currentMonthDays = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate(); // Days in the current month

    // Calculate balance, per day amount, and payable amount
    const balance = (investmentAmountNum * rateOfInterestNum) / 100;
    const perDayAmount = balance / currentMonthDays;
    const payableAmount = perDayAmount * selectedDays;

    console.log('Debug Info', {
      timeDifference,
      selectedDays,
      currentDate,
      currentMonthDays,
      balance,
      perDayAmount,
      payableAmount,
    });

    setPayableAmount(payableAmount.toFixed(2)); // Store the calculated amount
    setSelectedMember(member); // Store the selected member
    setCalculationModal(true); // Open modal
  };

  const handlePay = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    // Prepare data to save
    const paymentData = {
      PaymentBy: userId,
      memberUId: selectedMember.createdUserId,
      paymnetId: selectedMember.id,
      memberBVid: selectedMember.createdByBvId,
      memberName: selectedMember.createdBy,
      Remarks: selectedMember.tModeType,
      investmentAmount: selectedMember.investmentAmount,
      investmentdate: selectedMember.investmentdate,
      paymentType,
      referenceNo,
      payableAmount,
      timestamp: serverTimestamp(), // Current date and time
      date: new Date().toLocaleDateString(), // Current date in 'MM/DD/YYYY' format
      monthName: new Date().toLocaleString('default', { month: 'long' }), // Current month name (e.g., 'January')
      year: new Date().getFullYear(),
      type: 'credit',
      message: 'PROFIT SHARE INCOME',
      withdrawalStatus: 'no',
    };

    try {
      // Save data to Firestore
      await addDoc(collection(db, 'investmentPayments'), paymentData);

      toast.success('Payment data saved successfully!');
      closeModal();
    } catch (error) {
      console.error('Error saving payment data: ', error);
      toast.error('Failed to save payment data.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const closeModal = () => {
    setCalculationModal(false);
    setPayableAmount(null);
    setSelectedMember(null);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
        <Box sx={{ padding: 2 }}>
          <Typography
            level="h2"
            fontWeight="lg"
            sx={{
              mb: 2,
              color: 'white',
              textAlign: 'left',
              fontSize: { xs: '1.5rem', sm: '2rem' },
              marginTop: 6,
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            }}
          >
            Paymentable Account
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <FormControl sx={{ marginBottom: 2 }}>
              <FormLabel>To Date:</FormLabel>
              <Input
                name="toDate"
                value={formData.toDate}
                onChange={handleInputChange}
                required
                type="date"
                style={{ background: '#1a2a3385', color: 'white' }}
              />
            </FormControl>
            <FormControl sx={{ marginBottom: 2 }}>
              <FormLabel>From Date:</FormLabel>
              <Input
                name="fromDate"
                value={formData.fromDate}
                onChange={handleInputChange}
                required
                type="date"
                style={{ background: '#1a2a3385', color: 'white' }}
              />
            </FormControl>
          </div>
          <Sheet variant="soft">
            {/* Data Table */}
            <Table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                margin: '0 auto',
              }}
              sx={{
                overflow: { sm: 'hidden' },
              }}
            >
              <thead>
                <tr
                  style={{ backgroundColor: 'rgba(0,0,0,0.1)', color: 'white' }}
                >
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Payment To
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Payment Mode
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Investment Amount
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Status
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Type
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {members.map((member) => (
                  <tr key={member.id} style={{ color: 'black' }}>
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {member.createdBy}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {member.paymentModeId}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {member.investmentAmount}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {member.status}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {member.tModeType}
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        border: '1px solid white',
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => calculatePayableAmount(member)}
                        style={{ margin: '0 4px' }}
                        disabled={!isPayNowEnabled(member.createdUserId)}
                      >
                        Pay Now
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        </Box>
        {/* Modal for displaying calculated amount */}
        <Modal open={calculationModal} onClose={closeModal}>
          <Box
            sx={{
              width: 300,
              margin: 'auto',
              mt: '10%',
              padding: 2,
              backgroundColor: 'white',
              borderRadius: '8px',
              textAlign: 'center',
            }}
          >
            <Typography level="h4" sx={{ mb: 2 }}>
              Payment Details
            </Typography>
            {selectedMember && (
              <>
                <Typography level="body2">
                  For Member: {selectedMember.createdBy}
                </Typography>
                <Typography level="body1" sx={{ mt: 1 }}>
                  Payable Amount: {payableAmount}
                </Typography>
                {/* Payment Type Dropdown */}
                <Typography level="body2" sx={{ mt: 2, textAlign: 'left' }}>
                  Payment Type:
                </Typography>
                <select
                  value={paymentType}
                  onChange={(e) => setPaymentType(e.target.value)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    marginBottom: '16px',
                  }}
                >
                  <option value="" disabled>
                    Select Payment Type
                  </option>
                  <option value="Cash">Cash</option>
                  <option value="Bank">Bank</option>
                  <option value="Crypto">Crypto</option>
                </select>

                {/* Reference Number Input */}
                <Typography
                  level="body2"
                  sx={{ textAlign: 'left', fontWeight: '20px' }}
                >
                  Reference No:
                </Typography>
                <input
                  type="text"
                  value={referenceNo}
                  onChange={(e) => setReferenceNo(e.target.value)}
                  placeholder="Enter Reference No"
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                  }}
                />
              </>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="outlined"
                color="danger"
                onClick={closeModal}
                sx={{ mt: 2 }}
              >
                Close
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={handlePay}
                disabled={isSubmitting || !paymentType} // Disable button while submitting or if inputs are empty
                sx={{ mt: 2 }}
              >
                {isSubmitting ? 'Saving...' : 'Pay'}
              </Button>
            </div>
          </Box>
        </Modal>
      </CssVarsProvider>
    </div>
  );
};

export default Payment;
