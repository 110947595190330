import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Sheet,
  Button,
  Modal,
  Table,
  Input,
  FormControl,
  FormLabel,
} from '@mui/joy';
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  where,
  query,
  getDoc,
  writeBatch,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import { CssVarsProvider } from '@mui/joy/styles';
import framesxTheme from '../../theme';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase';
import front1 from '../../assets/IMG-20241124-WA0018.jpg';

const WithdrawalRequest = () => {
  const { user } = useAuth();
  const userId = user.uid;
  const [withdrawalReq, setWithdrawalReq] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [rateOfInterest, setRateOfInterest] = useState('');
  const [roiStatus, setRoiStatus] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log('withdrawalReq', withdrawalReq);

  useEffect(() => {
    const fetchWithdrawalReq = async () => {
      try {
        const withdrawalRequestsCollection = collection(
          db,
          'investmentPaymentWithdrawal'
        );
        const q = query(
          withdrawalRequestsCollection,
          where('withdrawalStatus', '==', 'pending')
        );
        const querySnapshot = await getDocs(q);

        const reqList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setWithdrawalReq(reqList);
      } catch (error) {
        console.error(
          'Error fetching withdrawal requests from Firebase:',
          error
        );
        toast.error('Error fetching withdrawal requests from Firebase!');
      }
    };

    fetchWithdrawalReq();
  }, []);

  const updateMemberStatus = async (id, newStatus) => {
    try {
      const memberRef = doc(db, 'investmentPaymentWithdrawal', id);
      await updateDoc(memberRef, { status: newStatus });

      setWithdrawalReq((prevMembers) =>
        prevMembers.map((member) =>
          member.id === id ? { ...member, status: newStatus } : member
        )
      );
      toast.success(`Member ${id} status updated to ${newStatus}!`);
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status!');
    }
  };

  // const handleAccept = async (id) => {
  //   // const member = withdrawalReq.find((m) => m.id === memberId);
  //   // setSelectedMember(member);
  //   // setIsModalOpen(true);
  //   try {
  //     await updateDoc(doc(db, 'investmentPaymentWithdrawal', id), {
  //       withdrawalStatus: 'accepted',
  //     });
  //     toast.success('withdrawal Accepted!');
  //   } catch (error) {
  //     console.error('Error withdrawal Accepted: ', error);
  //     toast.error('Failed to withdrawal Accepted.');
  //   }
  // };

  const handleAccept = async (id) => {
    try {
      const withdrawalDocRef = doc(db, 'investmentPaymentWithdrawal', id);
      const withdrawalDoc = await getDoc(withdrawalDocRef);
      const withdrawalData = withdrawalDoc.data();

      // Update the withdrawal status in investmentPaymentWithdrawal collection
      await updateDoc(withdrawalDocRef, {
        withdrawalStatus: 'accepted',
      });

      // Find and update the matching investmentPayments documents
      const investmentPaymentsCollection = collection(db, 'investmentPayments');
      const q = query(
        investmentPaymentsCollection,
        where('memberUId', '==', withdrawalData.memberUId)
      );
      const querySnapshot = await getDocs(q);

      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        batch.update(doc.ref, { withdrawalStatus: 'yes' });
      });
      await batch.commit();

      toast.success('Withdrawal accepted and investment payments updated!');
    } catch (error) {
      console.error('Error accepting withdrawal: ', error);
      toast.error('Failed to accept withdrawal.');
    }
  };

  const handleDone = async (id) => {
    // if (!selectedMember) return;

    // try {
    //   await updateDoc(doc(db, 'investmentPaymentWithdrawal', selectedMember.id), {
    //     status: 'Accepted',
    //     ActiveStatus: 'Active',
    //     rateOfInterest,
    //     roiStatus,
    //   });
    //   toast.success('Member updated successfully!');
    //   setIsModalOpen(false);
    //   setSelectedMember(null);
    //   setRateOfInterest('');
    //   setRoiStatus('');
    // } catch (error) {
    //   console.error('Error updating member: ', error);
    //   toast.error('Failed to update member.');
    // }
    updateMemberStatus(id, 'Accepted');
  };

  const handleReject = (id) => {
    updateMemberStatus(id, 'Rejected');
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };
  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
        <Box sx={{ padding: 2 }}>
          <Typography
            level="h2"
            fontWeight="lg"
            sx={{
              mb: 2,
              color: 'white',
              textAlign: 'left',
              fontSize: { xs: '1.5rem', sm: '2rem' },
              marginTop: 6,
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            }}
          >
            Withdrawal Approval Request List
          </Typography>
          <Sheet variant="soft">
            {/* Data Table */}
            <Table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                margin: '0 auto',
              }}
              sx={{
                overflow: { sm: 'hidden' },
              }}
            >
              <thead>
                <tr
                  style={{ backgroundColor: 'rgba(0,0,0,0.1)', color: 'white' }}
                >
                  {/* <th style={{ padding: '8px', border: '1px solid white' }}>ID</th> */}
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Amount
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Member ID
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Member Name
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Timestamp
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Wallet
                  </th>
                  <th style={{ padding: '8px', border: '1px solid white' }}>
                    Withdrawal Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {withdrawalReq.map((member) => (
                  <tr key={member.id} style={{ color: 'black' }}>
                    {/* <td style={{ padding: '8px', border: '1px solid white' }}>{member.id}</td> */}
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {member.amount}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {member.memberId}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {member.memberName}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {new Date(
                        member.timestamp.seconds * 1000
                      ).toLocaleString()}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {member.wallet}
                    </td>
                    <td style={{ padding: '8px', border: '1px solid white' }}>
                      {member.withdrawalStatus}
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        border: '1px solid white',
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => handleAccept(member.id)}
                        style={{ margin: '0 4px' }}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="outlined"
                        color="danger"
                        onClick={() => handleReject(member.id)}
                        style={{ margin: '0 4px' }}
                      >
                        Reject
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        </Box>
      </CssVarsProvider>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            padding: 2,
            backgroundColor: 'white',
            borderRadius: '8px',
            width: '300px',
            margin: 'auto',
            marginTop: '20vh',
          }}
        >
          <Typography level="h6" mb={2}>
            Update Member
          </Typography>
          <FormControl sx={{ marginBottom: 2 }}>
            <FormLabel>Rate of Interest:</FormLabel>
            <Input
              name="rateOfInterest"
              value={rateOfInterest}
              onChange={(e) => setRateOfInterest(e.target.value)}
              required
              type="number"
              style={{ background: '#1a2a3385', color: 'white' }}
            />
          </FormControl>
          <FormControl sx={{ marginBottom: 2 }}>
            <FormLabel>ROI Status:</FormLabel>
            <select
              name="roiStatus"
              value={roiStatus}
              onChange={(e) => setRoiStatus(e.target.value)}
              required
              style={{ background: '#1a2a3385', color: 'white' }}
            >
              <option value="">Select ROI Status</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDone}
            style={{ background: '#1a2a3385', color: 'white' }}
          >
            Done
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default WithdrawalRequest;
