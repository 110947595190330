import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
} from "@mui/joy";
import { db } from "../../firebase"; // Firebase configuration import
import { collection, getDocs, query, where } from "firebase/firestore";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";
import front1 from "../../assets/IMG-20241124-WA0018.jpg";
import Table from "../../components/Table";
import framesxTheme from '../../theme';
import { CssVarsProvider } from '@mui/joy/styles';



function WithdrawalReport() {
  const { user } = useAuth();
  const [settlements, setSettlements] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log('setSettlements', settlements);

  // Format Firebase timestamp to human-readable date format (DD-MM-YYYY)
  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return "Invalid Date";
    const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  useEffect(() => {
    const fetchSettlements = async () => {
      try {
        const currentUserUid = user.uid; // Replace this with the actual UID of the current user (e.g., fetched from auth)

        const q = query(
          collection(db, "oldsettlementPayment")
        );

        const querySnapshot = await getDocs(q);
        const settlementsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Transform timestamp to date format before updating state
        const transformedSettlements = settlementsData.map((settlement) => ({
          ...settlement,
          timestamp: formatDate(settlement.timestamp), // Convert timestamp to date format
        }));

        setSettlements(transformedSettlements);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        toast.error("Error fetching data.");
        setLoading(false);
      }
    };

    fetchSettlements();
  }, []);

  const columns = [
    { header: "ID", accessorKey: "id" },
    {
      header: "Date",
      accessorKey: "timestamp",
    },
    { header: "Name", accessorKey: "ClinName" },
    { header: "Approved USDT$", accessorKey: "approvedamount" },
    { header: "Withdrawal USDT$", accessorKey: "givenBalance" },
    { header: "Remaining amount USDT$", accessorKey: "remainingBalance" },
    { header: "Active Status", accessorKey: "ActiveStatus" },
  ];


  // Calculate totals for footer
  const approvedAmountTotal = settlements.reduce((sum, item) => sum + (parseFloat(item.approvedamount) || 0), 0);
  const givenBalanceTotal = settlements.reduce((sum, item) => sum + (parseFloat(item.givenBalance) || 0), 0);
  console.log('approvedAmountTotal', approvedAmountTotal, givenBalanceTotal);
  const footerData = [
    {
      id: "Total",
      // ClinName: "Total",
      approvedamount: approvedAmountTotal,
      givenBalance: givenBalanceTotal,
    },
  ];

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
        <Box sx={{ padding: 2 }}>
          <Typography
            level="h2"
            fontWeight="lg"
            sx={{
              mb: 2,
              color: 'white',
              textAlign: 'left',
              fontSize: { xs: '1.5rem', sm: '2rem' },
              marginTop: 6,
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            }}
          >
            Withdrawal Report
          </Typography>
          {settlements.length > 0 ? (
            <Table columns={columns} data={settlements} footerData={footerData} />
          ) : (
            <Typography style={{ color: "black" }}>
              No members found.
            </Typography>
          )}
        </Box>
      </CssVarsProvider>
    </div>
  );
}

// Table header and cell styling
const tableHeaderStyle = {
  padding: "10px",
  borderBottom: "2px solid rgba(255, 255, 255, 0.3)", // Semi-transparent border
  textAlign: "center",
  backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent white background for headers
  color: "black", // White text
};

const tableCellStyle = {
  padding: "10px",
  borderBottom: "1px solid rgba(255, 255, 255, 0.2)", // Semi-transparent border
  textAlign: "center",
  backgroundColor: "#1a2a339c", // Transparent white background
  color: "#FFFFFF", // White text
};

export default WithdrawalReport;
