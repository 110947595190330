import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
} from "@mui/joy";
import { CssVarsProvider } from "@mui/joy/styles";
import framesxTheme from "../../theme";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import SupportSystem from "../support/SupportSystem";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, setDoc, where, query, getDocs, collection } from "firebase/firestore";
import { useAuth } from "../../context/AuthContext";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import front1 from "../../assets/IMG-20241124-WA0018.jpg";

function KycVerification() {
  const auth = getAuth();
  const db = getFirestore();
  const { user } = useAuth();
console.log('user',user);

  const [selectedModalOption, setSelectedModalOption] = useState("");
  const [countries, setCountries] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const storage = getStorage();
  const [formData, setFormData] = useState({
    idcardNumber: "",
    country: "India",
    dob: "",
    idProofImageFront: "",
    idProofImageBack: "",
    idProofImageholding: "",
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid mobile number")
      .required("Mobile Number is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
  });


  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        'https://restcountries.com/v3.1/all?fields=name,flags'
      );
      setCountries(response.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
      toast.error('Error fetching countries!');
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);
  
  // const handleInputChange = async (event) => {
  //   const { name, value, files } = event.target;

  //   if (name === 'front-image' || name === 'back-image') {
  //     const file = files[0];
  //     if (file) {
  //       try {
  //         const imageRef = ref(storage, `images/${name}/${file.name}`);
  //         await uploadBytes(imageRef, file);
  //         const downloadURL = await getDownloadURL(imageRef);

  //         // Update formData with the correct URL for front or back image
  //         setFormData((prevData) => ({
  //           ...prevData,
  //           [name === 'front-image' ? 'idProofImageFront' : 'idProofImageBack']:
  //             downloadURL,
  //         }));
  //         toast.success(
  //           `${
  //             name === 'front-image' ? 'Front' : 'Back'
  //           } Image uploaded successfully!`
  //         );
  //       } catch (error) {
  //         console.error('Error uploading image:', error);
  //         toast.error('Failed to upload image');
  //       }
  //     }
  //   } else {
  //     setFormData({ ...formData, [name]: value });
  //   }
  // };

  const handleInputChange = async (event) => {
    const { name, value, files } = event.target;

    if (
      name === "front-image" ||
      name === "back-image" ||
      name === "holding-image"
    ) {
      const file = files[0];
      if (file) {
        try {
          // Determine the storage folder dynamically based on input name
          const imageRef = ref(storage, `images/${name}/${file.name}`);
          await uploadBytes(imageRef, file);
          const downloadURL = await getDownloadURL(imageRef);

          // Update formData with the correct URL for front, back, or holding image
          setFormData((prevData) => ({
            ...prevData,
            [name === "front-image"
              ? "idProofImageFront"
              : name === "back-image"
              ? "idProofImageBack"
              : "idProofImageHolding"]: downloadURL,
          }));

          toast.success(
            `${
              name === "front-image"
                ? "Front"
                : name === "back-image"
                ? "Back"
                : "Holding"
            } Image uploaded successfully!`
          );
        } catch (error) {
          console.error("Error uploading image:", error);
          toast.error("Failed to upload image");
        }
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   try {
  //     const userId = user ? user.uid : null;
  //     const userEmail = user ? user.email : null;

  //     // Only proceed if user is authenticated
  //     if (userId) {
  //       const kycData = {
  //         ...formData,
  //         idProofType: selectedModalOption,
  //         status: "pending",
  //         userId,
  //         userEmail,
  //       };

  //       // Save data to Firestore
  //       await setDoc(doc(db, "kycVerifications", userId), kycData);

  //       toast.success("Form submitted successfully!");
  //       // Clear the form data after successful submission
  //       setFormData({
  //         idcardNumber: "",
  //         country: "India",
  //         dob: "",
  //         idProofImageFront: "",
  //         idProofImageBack: "",
  //         idProofImageholding: "",
  //       });
  //       setSelectedModalOption("");
  //       setSelectedFile(null); // Clear the selected file if needed
  //     } else {
  //       toast.error("User not authenticated!");
  //     }
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //     toast.error("Error submitting form!");
  //   }
  // };


  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const userId = user ? user.uid : null;
      const userEmail = user ? user.email : null;
  
      // Only proceed if user is authenticated
      if (userId) {
        // Reference to the "kycVerifications" collection
      const kycCollectionRef = collection(db, "kycVerifications");

      // Query to check if ID proof number exists
      const q = query(kycCollectionRef, where("idcardNumber", "==", formData.idcardNumber));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        toast.error("This ID proof number is already in use.");
        return;
      }
  
        const kycData = {
          ...formData,
          idProofType: selectedModalOption,
          status: "pending",
          userId,
          userEmail,
        };
  
        // Save data to Firestore
        await setDoc(doc(db, "kycVerifications", userId), kycData);
  
        toast.success("Form submitted successfully!");
        // Clear the form data after successful submission
        setFormData({
          idcardNumber: "",
          country: "India",
          dob: "",
          idProofImageFront: "",
          idProofImageBack: "",
          idProofImageholding: "",
        });
        setSelectedModalOption("");
        setSelectedFile(null); // Clear the selected file if needed
      } else {
        toast.error("User not authenticated!");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form!");
    }
  };
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <Box
        sx={{
          padding: 2,
          minHeight: "100vh",
          position: "relative", // Required for positioning children
          overflow: "hidden", // Ensures content doesn't spill outside
        }}
      >
        <img
          src={front1}
          alt="Background"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1, // Places the image behind the content
          }}
        />
        <Typography
          level="h2"
          style={{ color: "#FFFFFF" }}
          fontWeight="lg"
          sx={{
            fontSize: { xs: "1.5rem", md: "2rem" },
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            marginTop: 5,
            textAlign: "left",
          }}
        >
          KYC Verification
        </Typography>
        <Sheet
          variant="soft"
          sx={{
            padding: { xs: 2, sm: 3 },
            borderRadius: "sm",
            maxWidth: "100%",
            marginTop: 2,
            marginX: "auto",
            backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white
            backdropFilter: "blur(10px)", // Optional: Adds a blur effect for the content behind
            boxShadow: "none",
          }}
          bgcolor="neutral.softHoverBg"
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Select Your National ID :</FormLabel>
                <select
                  id="id-proof"
                  className="px-2 py-2 border rounded bg-transparent border-gray-600 w-full"
                  value={selectedModalOption}
                  required
                  onChange={(e) => setSelectedModalOption(e.target.value)}
                  style={{
                    backgroundColor: "#1a2a339c",
                    color: "white",
                  }}
                >
                  <option value="" disabled>
                    Select Your ID Proof
                  </option>
                  <option value="Pan">PAN Card</option>
                  <option value="Adher">Aadhaar Card</option>
                  <option value="Voter">Voter Card</option>
                  <option value="National">National ID</option>
                  <option value="Driving Licence">Driving Licence</option>
                </select>
                {selectedModalOption && (
                  <Box sx={{ marginTop: 2 }}>
                    <FormLabel>{selectedModalOption} Number:</FormLabel>
                    <input
                      type="text"
                      placeholder={`Enter your ${selectedModalOption} Number`}
                      name="idcardNumber"
                      required
                      value={formData.idcardNumber}
                      onChange={handleInputChange}
                      className="px-2 py-2 border rounded bg-transparent border-gray-600 w-full"
                      style={{
                        backgroundColor: "#1a2a339c",
                        color: "white",
                      }}
                    />
                  </Box>
                )}
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  flexDirection: { xs: "column", sm: "row" }, // Stacks vertically on small screens, horizontally on larger screens
                  width: "100%",
                  flexWrap: "wrap", // Allows wrapping of items on smaller screens
                }}
              >
                <FormControl sx={{ flex: 1, minWidth: 0 }}>
                  <label
                    htmlFor="front-image"
                    className="text-gray-600 font-semibold my-1"
                  >
                    ID Image [Front Side]
                  </label>
                  <input
                    type="file"
                    id="front-image"
                    name="front-image"
                    className="w-full h-10 border rounded-md focus:outline-none focus:border-blue-500 px-4 mb-4 drop-shadow"
                    onChange={handleInputChange}
                    style={{
                      backgroundColor: "#1a2a339c",
                      color: "white",
                    }}
                  />
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: 0 }}>
                  <label
                    htmlFor="back-image"
                    className="text-gray-600 font-semibold my-1"
                  >
                    ID Image [Back Side]
                  </label>
                  <input
                    type="file"
                    id="back-image"
                    name="back-image"
                    className="w-full h-10 border rounded-md focus:outline-none focus:border-blue-500 px-4 mb-4 drop-shadow"
                    onChange={handleInputChange}
                    style={{
                      backgroundColor: "#1a2a339c",
                      color: "white",
                    }}
                  />
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: 0 }}>
                  <label
                    htmlFor="holding-image"
                    className="text-gray-600 font-semibold my-1"
                  >
                    Photo of you holding your ID
                  </label>
                  <input
                    type="file"
                    id="holding-image"
                    name="holding-image"
                    className="w-full h-10 border rounded-md focus:outline-none focus:border-blue-500 px-4 mb-4 drop-shadow"
                    onChange={handleInputChange}
                    style={{
                      backgroundColor: "#1a2a339c",
                      color: "white",
                    }}
                  />
                </FormControl>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 2,
                }}
              >
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 2,
                }}
              >
                <Button
                  type="submit"
                  variant="solid"
                  color="success"
                  sx={{ marginTop: 2, width: { xs: "100%", sm: "auto" } }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        </Sheet>
      </Box>
      <SupportSystem />
    </CssVarsProvider>
  );
}

export default KycVerification;
