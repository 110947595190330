import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
} from "@mui/joy";
import { db } from "../../firebase"; // Firebase configuration import
import { collection, getDocs } from "firebase/firestore";
import { toast } from "react-toastify";
import ModeIcon from "@mui/icons-material/Mode";
import { useNavigate } from "react-router-dom";
import front1 from "../../assets/IMG-20241124-WA0018.jpg";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function OldSettleMentView() {
  const navigate = useNavigate();
  const [settlements, setSettlements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    const fetchSettlements = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "oldSettlement"));
        const settlementsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSettlements(settlementsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        toast.error("Error fetching data.");
        setLoading(false);
      }
    };

    fetchSettlements();
  }, []);

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />

      {/* Page Content */}
      <Box
        sx={{
          padding: 2,
          margin: "0 auto",
          maxWidth: "1200px",
          color: "#fff",
          textAlign: "left",
        }}
      >
        <Typography
          level="h2"
          sx={{
            fontSize: { xs: "1.5rem", md: "2rem" },
            fontWeight: "bold",
            marginBottom: 3,
            color: "#fff"
          }}
        >
          Old Settlements
        </Typography>
        <Button
          variant="solid"
          onClick={() => navigate("/oldsettlement")}
          sx={{ marginBottom: 2, backgroundColor: "#6b6c6d" }}
        >
          <AddCircleOutlineIcon />
        </Button>
        {/* Responsive Table */}
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              color: "#FFFFFF",
              backgroundColor: "#333",
            }}
          >
            <thead>
              <tr>
                {[
                  "SL.No", 
                   "Date",
                  "Name",
                  "Old ID",
                  "Recommended Leader Name",
                  "Recommended Leader ID",
                  "Amount",
                  "Balance",
                  "Approved Amount",
                  "Remaining Balance",
                  "Percentage",
                  "Dashboard Image",
                  "Wallet Image",
                  "Action",
                ].map((header) => (
                  <th key={header} style={tableHeaderStyle}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {settlements.map((settlement, i) => (
                <tr key={settlement.id}>
                <td style={tableCellStyle}>{i + 1}</td>
                <td style={tableCellStyle}>
                  {new Intl.DateTimeFormat('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  }).format(new Date(settlement.timestamp.seconds * 1000))}
                </td>
                  <td style={tableCellStyle}>{settlement.ClinName}</td>
                  <td style={tableCellStyle}>{settlement.SdfxId}</td>
                  <td style={tableCellStyle}>{settlement.leaderName}</td>
                  <td style={tableCellStyle}>{settlement.leaderId}</td>
                  <td style={tableCellStyle}>{settlement.amount}</td>
                  <td style={tableCellStyle}>{settlement.balance}</td>
                  <td style={tableCellStyle}>{settlement.approvedamount}</td>
                  <td style={tableCellStyle}>{settlement.remainingBalance}</td>
                  <td style={tableCellStyle}>{settlement.percentage}</td>
                  <td style={tableCellStyle}>
                    <img
                      src={settlement.dashboardImage}
                      alt="Dashboard"
                      onClick={() =>
                        handleImageClick(settlement.dashboardImage)
                      }
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                  <td style={tableCellStyle}>
                    <img
                      src={settlement.walletImage}
                      alt="Wallet"
                      onClick={() => handleImageClick(settlement.walletImage)}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                  <td style={tableCellStyle}>
                    <Button
                    onClick={() =>
                      navigate(`/oldsettlement/${settlement.id}`)
                    }
                    variant="outlined"
                    color="primary"
                    size="small">
                      <ModeIcon />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Box>

      {/* Modal for Image Preview */}
      <Modal open={!!selectedImage} onClose={closeModal}>
        <Box
          sx={{
            padding: 2,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "90vw",
          }}
        >
          <img
            src={selectedImage}
            alt="Enlarged"
            style={{
              maxWidth: "90%",
              maxHeight: "30vh",
              borderRadius: "8px",
            }}
          />
          <Button onClick={closeModal} color="danger" sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

// Table header and cell styling
const tableHeaderStyle = {
  padding: "8px",
  borderBottom: "2px solid #555",
  textAlign: "left",
  backgroundColor: "#444",
};

const tableCellStyle = {
  padding: "8px",
  borderBottom: "1px solid #555",
  textAlign: "center",
};

export default OldSettleMentView;
