import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Sheet,
  Input,
  Button,
} from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import framesxTheme from '../../theme';
import SupportSystem from '../support/SupportSystem';
import front1 from '../../assets/IMG-20241124-WA0018.jpg';
import { useAuth } from '../../context/AuthContext';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';

function PrincipleWithdrawal() {
  const { user } = useAuth();
  const [selectedModalOption, setSelectedModalOption] = useState('');
  const [cryptoAddress, setCryptoAddress] = useState('');
  const [error, setError] = useState('');

  console.log('user', cryptoAddress);

  useEffect(() => {
    // Fetch crypto data from Firestore
    const fetchCryptoData = async () => {
      try {
        const cryptoCollection = collection(db, 'cryptoAddresses'); // Collection name in Firestore
        const q = query(cryptoCollection, where('uid', '==', user.uid)); // Query to find matching UID
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const cryptoDoc = querySnapshot.docs[0].data(); // Get the first matching document
          setCryptoAddress(cryptoDoc.cryptoAddress); // Set the crypto address
        } else {
          setError('No matching crypto address found for this user.');
        }
      } catch (err) {
        console.error('Error fetching crypto data:', err);
        setError('Failed to fetch crypto address. Please try again.');
      }
    };

    fetchCryptoData();
  }, [user.uid, db]);

  const handleSlotChange = (slot) => {
    console.log('Selected slot:', slot);
  };
  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <Box
        sx={{
          padding: 2,
          minHeight: '100vh',
          position: 'relative', // Required for positioning children
          overflow: 'hidden', // Ensures content doesn't spill outside
        }}
      >
        <img
          src={front1}
          alt="Background"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1, // Places the image behind the content
          }}
        />

        <Typography
          level="h2"
          style={{
            color: '#FFFFFF',
            marginTop: 30,
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
          }}
          fontWeight="lg"
        >
          Principal Withdrawal Request
        </Typography>
        <Sheet
          variant="soft"
          sx={{
            padding: 3,
            borderRadius: 'sm',
            maxWidth: 1500,
            marginTop: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent white
            backdropFilter: 'blur(10px)', // Optional: Adds a blur effect for the content behind
            boxShadow: 'none',
          }}
          bgcolor="neutral.softHoverBg"
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl sx={{ flex: 1, minWidth: '200px' }}>
              <FormLabel style={{ color: 'black' }}>
                Available Balance :
              </FormLabel>
              <Input
                placeholder="$34.57 USD"
                sx={{ backgroundColor: '#1a2a339c', color: 'white' }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Wallet :</FormLabel>
              <select
                id="timeSlot"
                className="px-2 py-2 border rounded bg-transparent border-gray-600"
                value={selectedModalOption}
                style={{ backgroundColor: '#1a2a339c', color: 'white' }}
                onChange={(e) => {
                  const slot = e.target.value;
                  handleSlotChange(slot);
                  setSelectedModalOption(slot);
                }}
              >
                <option value="" disabled>
                  Select Your Wallet
                </option>
                <option value="TRC20">TRC20</option>
                <option value="BEP20">BEP20(BSC)</option>
              </select>
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel style={{ color: 'black' }}>
                Amount to Withdraw :
              </FormLabel>
              <Input
                placeholder="$34.57 USD"
                sx={{ backgroundColor: '#1a2a339c', color: 'white' }}
              />
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel style={{ color: 'black' }}>Address :</FormLabel>
              <Input
                placeholder="Set your Crypto Address"
                value={cryptoAddress}
                readOnly
                sx={{ backgroundColor: '#1a2a339c', color: 'white' }}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 2,
            }}
          >
            <Button variant="solid" color="success">
              Continue
            </Button>
          </Box>
        </Sheet>
      </Box>
      <SupportSystem />
    </CssVarsProvider>
  );
}

export default PrincipleWithdrawal;
