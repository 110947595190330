import React, { useEffect, useState } from 'react';
import { Typography, Button, Sheet } from '@mui/joy';
import { db } from '../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import front1 from '../../assets/IMG-20241124-WA0018.jpg';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DrawIcon from '@mui/icons-material/Draw';

function InvestmentDistributionReport() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [settlements, setSettlements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [investmentSegment, setInvestmentSegment] = useState('');


  useEffect(() => {
    const fetchSettlements = async () => {
      try {
        let q = query(collection(db, 'investmentDistribution'));

        if (fromDate) {
          q = query(q, where('createdAt', '>=', new Date(fromDate)));
        }
        if (toDate) {
          q = query(q, where('createdAt', '<=', new Date(toDate)));
        }
        if (investmentSegment) {
          q = query(q, where('investmentSegment', '==', investmentSegment));
        }

        const querySnapshot = await getDocs(q);
        const settlementsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSettlements(settlementsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data: ', error);
        toast.error('Error fetching data.');
        setLoading(false);
      }
    };

    fetchSettlements();
  }, [fromDate, toDate, investmentSegment]);

  // Format the settlement data
  const formattedData = settlements.map((item) => ({
    ...item,
    createdAt: item.createdAt
      ? new Date(item.createdAt.seconds * 1000).toLocaleString()
      : 'No timestamp available',
    investmentAccount: item.investmentAccount || '0',
    mt5Id: item.mt5Id || '0',
    password: item.password || '0',
    mt5Password: item.mt5Password || '0',
    email: item.email || 'N/A',
  }));

  return (
    <div style={{ position: 'relative', minHeight: '100vh', padding: '1rem' }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      />
      {/* Header Section */}
      <Typography
        level="h2"
        style={{ color: '#FFFFFF' }}
        fontWeight="lg"
        sx={{ fontSize: { xs: '1.5rem', md: '2rem' }, marginTop: '35px' }}
      >
        Investment Distribution Report
      </Typography>
      <div>
        <label>From Date:</label>
        <input
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        />
        <label>To Date:</label>
        <input
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
        />
        <label>Investment Segment:</label>
        <select
          // value={investmentSegment}
          onChange={(e) => setInvestmentSegment(e.target.value)}
        >
          <option value="">Select Segment</option>
          <option value="Crypto">Crypto</option>
          <option value="FCX">FCX</option>
          <option value="ROX">ROX</option>
          <option value="Multi_Bank">Multi Bank</option>
          <option value="Indian_Market">Indian Market</option>
        </select>
      </div>
      {/* Table Section */}
      <Sheet
        sx={{
          width: '100%',
          overflowX: 'auto',
          backgroundColor: '#40474F',
          padding: '1rem',
          borderRadius: '8px',
          marginTop: '20px',
        }}
      >
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            color: '#FFFFFF',
            textAlign: 'left',
          }}
        >
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Date & Time</th>
              <th style={tableHeaderStyle}>Investment Segment</th>
              <th style={tableHeaderStyle}>Email</th>
              <th style={tableHeaderStyle}>Investment Amount</th>
              <th style={tableHeaderStyle}>As On Value</th>
              <th style={tableHeaderStyle}>Password</th>
              <th style={tableHeaderStyle}>MT5 ID</th>
              <th style={tableHeaderStyle}>MT5 Password</th>
              {/* <th style={tableHeaderStyle}>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {!loading ? (
              formattedData.map((item) => (
                <tr key={item.id}>
                  <td style={tableCellStyle}>{item.createdAt}</td>
                  <td style={tableCellStyle}>{item.investmentSegment}</td>
                  <td style={tableCellStyle}>{item.email}</td>
                  <td style={tableCellStyle}>{item.investmentAccount}</td>
                  <td style={tableCellStyle}>{item.asonvalue}</td>
                  <td style={tableCellStyle}>{item.password}</td>
                  <td style={tableCellStyle}>{item.mt5Id}</td>
                  <td style={tableCellStyle}>{item.mt5Password}</td>
                  {/* <td style={tableCellStyle}>
                    <Button
                      onClick={() =>
                        navigate(`/investmentdistribution/${item.id}`)
                      }
                      variant="outlined"
                      color="primary"
                      size="small"1`
                    >
                      <DrawIcon />
                    </Button>
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td style={tableCellStyle} colSpan="3">
                  Loading...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Sheet>
    </div>
  );
}

// Table header and cell styling
const tableHeaderStyle = {
  padding: '10px',
  borderBottom: '2px solid #555',
  backgroundColor: '#555',
  color: '#FFFFFF',
};

const tableCellStyle = {
  padding: '10px',
  borderBottom: '1px solid #777',
  backgroundColor: '#333',
  color: '#FFFFFF',
};

export default InvestmentDistributionReport;
