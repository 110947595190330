import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
} from '@mui/joy';
import { db } from '../../firebase'; // Firebase configuration import
import { collection, getDocs, query, where } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/AuthContext';
import front1 from '../../assets/IMG-20241124-WA0018.jpg';

function Wallet() {
  const { user } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [investmentWithdrawals, setInvestmentWithdrawals] = useState([]);
  const [combinedTransactions, setCombinedTransactions] = useState([]);

  const [loading, setLoading] = useState(true);
  console.log('userWA', user, combinedTransactions);

  useEffect(() => {
    const fetchSettlements = async () => {
      try {
        const currentUserUid = user.uid; // Replace this with the actual UID of the current user (e.g., fetched from auth)

        const q = query(
          collection(db, 'investmentPayments'),
          where('memberUId', '==', currentUserUid)
        );

        const querySnapshot = await getDocs(q);
        const settlementsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTransactions(settlementsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data: ', error);
        toast.error('Error fetching data.');
        setLoading(false);
      }
    };

    const fetchInvestmentWithdrawals = async () => {
      try {
        const investmentWithdrawalsCollection = collection(
          db,
          'investmentPaymentWithdrawal'
        );
        const q = query(
          investmentWithdrawalsCollection,
          where('memberUId', '==', user.uid),
          where('withdrawalStatus', '==', 'accepted'),
          where('type', '==', 'debit')
        );
        const querySnapshot = await getDocs(q);
        const withdrawalsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setInvestmentWithdrawals(withdrawalsData);
      } catch (error) {
        console.error('Error fetching investment withdrawals: ', error);
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchSettlements(), fetchInvestmentWithdrawals()]);
      setLoading(false);
    };

    fetchData();
  }, [user.uid]);

  // Combine and sort transactions whenever `transactions` or `investmentWithdrawals` change
  useEffect(() => {
    const combined = [...transactions, ...investmentWithdrawals];

    combined.sort(
      (a, b) =>
        new Date(b.timestamp.seconds * 1000) -
        new Date(a.timestamp.seconds * 1000)
    );

    setCombinedTransactions(combined);
  }, [transactions, investmentWithdrawals]);

  const calculateBalance = (transactions) => {
    // Sort transactions by date
    const sortedTransactions = transactions.sort(
      (a, b) =>
        new Date(a.timestamp.seconds * 1000) -
        new Date(b.timestamp.seconds * 1000)
    );

    let balance = 0;
    return sortedTransactions.map((transaction) => {
      if (transaction.type === 'credit') {
        balance += parseFloat(transaction.payableAmount);
      } else if (transaction.type === 'debit') {
        balance -= parseFloat(transaction.payableAmount);
      }
      return { ...transaction, balance };
    });
  };

  const transactionsWithBalance = calculateBalance(combinedTransactions);
  console.log('transactionsWithBalance', transactionsWithBalance);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
    return date.toLocaleDateString('en-GB'); // Format date to DD/MM/YYYY
  };

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1, // Places the image behind the content
        }}
      />
      <Typography
        level="h2"
        style={{ color: '#FFFFFF' }}
        fontWeight="lg"
        sx={{
          fontSize: { xs: '1.5rem', md: '2rem' },
          textAlign: 'left',
          marginTop: 6,
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.9)',
          marginLeft: 3,
        }}
      >
        Withdraw Wallet
      </Typography>
      <div style={{ overflowX: 'auto', maxWidth: '100%', padding: '10px' }}>
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            color: '#FFFFFF',
            backgroundColor: '#333',
            marginTop: '1rem',
            backgroundColor: 'rgba(51, 51, 51, 0.7)',
            // border: "1px solid rgba(255, 255, 255, 0.2)",
            borderRadius: 8,
          }}
        >
          <thead>
            <tr>
              <td
                style={{
                  ...tableCellStyle,
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  color: 'white',
                  borderRadius: 8,
                }}
              >
                Date
              </td>
              <td
                style={{
                  ...tableCellStyle,
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  color: 'white',
                }}
              >
                Remarks
              </td>
              <td
                style={{
                  ...tableCellStyle,
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  color: 'white',
                }}
              >
                Debit
              </td>
              <td
                style={{
                  ...tableCellStyle,
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  color: 'white',
                }}
              >
                Credit
              </td>
              <td
                style={{
                  ...tableCellStyle,
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  color: 'white',
                }}
              >
                Balance
              </td>
            </tr>
          </thead>
          <tbody
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.3)', // Semi-transparent white for tbody background
            }}
          >
            {transactionsWithBalance
              .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date in descending order
              .map((item) => (
                <tr
                  key={item.id}
                  style={{
                    borderBottom: '1px solid rgba(255, 255, 255, 0.5)', // Light border for row separation
                  }}
                >
                  <td
                    style={{
                      ...tableCellStyle,
                      backgroundColor: 'rgba(255, 255, 255, 0.1)', // Add slight white transparency to each cell
                    }}
                  >
                    {formatDate(item.timestamp)}
                  </td>
                  <td
                    style={{
                      ...tableCellStyle,
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    }}
                  >
                    {item.message}
                  </td>
                  <td
                    style={{
                      ...tableCellStyle,
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    }}
                  >
                    {item.type === 'debit' ? item.payableAmount : '0.00'}
                  </td>
                  <td
                    style={{
                      ...tableCellStyle,
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    }}
                  >
                    {item.type === 'credit' ? item.payableAmount : '0.00'}
                  </td>
                  <td
                    style={{
                      ...tableCellStyle,
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    }}
                  >
                    {item.balance}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

// Table header and cell styling
const tableHeaderStyle = {
  padding: '10px',
  borderBottom: '2px solid #555',
  textAlign: 'left',
  backgroundColor: '#555',
};

const tableCellStyle = {
  padding: '10px',
  borderBottom: '1px solid #555',
  backgroundColor: '#bcbcbc',
  color: 'black',
};

export default Wallet;
