import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
  Select,
  Option,
  // Modal,
} from "@mui/joy";
import { CssVarsProvider } from "@mui/joy/styles";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { addDoc, collection, doc, getDoc, getDocs, query, serverTimestamp, where } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../firebase";
import { useAuth } from "../../context/AuthContext";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import SupportSystem from ".././support/SupportSystem";
import front1 from "../../assets/IMG-20241124-WA0018.jpg";
import { useNavigate, useParams } from "react-router-dom";

const CreatePayment = () => {
  // const [user] = useState(JSON.parse(localStorage.getItem('user')));
  const { user } = useAuth();
  const { id } = useParams();

  // Define validation schema
  const [showModal, setShowModal] = useState(false);
  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    investmentAmount: Yup.number()
      .typeError("Investment Amount must be a number")
      .positive("Investment Amount must be positive")
      .required("Investment Amount is required"),
    brokerId: Yup.string().required("Broker Name is required"),
    paymentModeId: Yup.string().required("Payment Mode is required"),
    mt5Id: Yup.string().notRequired(),
    mt5Password: Yup.string().notRequired(),
  });
  const storage = getStorage();
  // Define state variables for form data, errors, form validity, payment types, and broker names
  const [formData, setFormData] = useState({
    mobileNumber: "",
    email: "",
    password: "",
    investmentAmount: 0,
    investmentdate: "",
    brokerId: "",
    paymentModeId: "",
    md5Userid: "",
    mt5Password: "",
    tModeType: "",
    imageUrl: "",
    givenBalance: 0,
    givenPercentage: 0,
    fromDate: "",
    toDate: "",
    totalAmount:0
  });
  console.log('formData', formData);
  const navigate = useNavigate()
  const [errors, setErrors] = useState({});
  const [req, setReq] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(false);
  // const [paymentTypes, setPaymentTypes] = useState([]);
  const [brokerNames, setBrokerNames] = useState([]);
  const [curentUserData, setCurentUserData] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const tenantsCollection = collection(db, 'members');

  const paymentTypes = [
    { paymentModeId: "1", paymentModeType: "Cash" },
    { paymentModeId: "2", paymentModeType: "TRC20" },
    // { paymentModeId: '3', paymentModeType: 'Card' },
  ];

  const types = [
    { typeModeId: "1", tModeType: "IB" },
    { typeModeId: "2", tModeType: "Investment" },
  ];
  const handleViewRequest = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (tenantsCollection) {
        const data = await fetchMembers(tenantsCollection);
        setCurentUserData(data[0]);

        console.log('111----------------->', data);
      }
    };
    fetchData(); // Call the async function
  }, []);

  async function fetchMembers(ref) {
    const snapshot = query(ref, where('email', '==', user?.email));
    const fetchData = await getDocs(snapshot);
    const results = [];

    fetchData.forEach((doc) => {
      const abc = { id: doc.id, ...doc.data() };
      results.push(abc);
    });

    if (results.length > 0) {
      return results;
    } else {
      return null;
    }
  }

  useEffect(() => {
    const fetchRequest = async () => {
      try {
        // Fetch the members collection from Firestore
        const querySnapshot = await getDocs(
          collection(db, "liveAccountRequests")
        );
        const liveAccountRequestsList = querySnapshot.docs
          .map((doc) => ({
            id: doc.id, // Firestore document ID
            ...doc.data(), // All other data in the document
          }))
          .filter((doc) => doc.createdBy === user.uid);

        console.log(
          "Fetched liveAccountRequests from Firebase:",
          liveAccountRequestsList
        );
        setReq(liveAccountRequestsList); // Set members in state
      } catch (error) {
        console.error(
          "Error fetching liveAccountRequests from Firebase:",
          error
        );
        toast.error("Error fetching liveAccountRequests from Firebase!");
      }
    };

    fetchRequest();
  }, []);


  const handleInputChange = async (event) => {
    const { name, value, files } = event.target;

    if (name === "image") {
      // If an image file is selected, upload it to Firebase Storage
      const file = files[0];
      if (file) {
        try {
          const imageRef = ref(storage, `images/${file.name}`);
          await uploadBytes(imageRef, file);
          const downloadURL = await getDownloadURL(imageRef);
          setFormData((prevData) => ({ ...prevData, imageUrl: downloadURL })); // Update formData with the image URL
          toast.success("Image uploaded successfully!");
        } catch (error) {
          console.error("Error uploading image:", error);
          toast.error("Failed to upload image");
        }
      }
    } else {
      setFormData({ ...formData, [name]: value });
      if (name === "fromDate" || name === "toDate") {
        const updatedFormData = { ...formData, [name]: value };
        calculateAmount(updatedFormData);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const docRef = doc(db, 'liveAccountRequests', id);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            console.log(data);

            setFormData(data);
          } else {
            toast.error('Document not found.');
          }
        } catch (error) {
          console.error('Error fetching document: ', error);
          toast.error('Failed to load data.');
        }
      }
    };

    fetchData();
  }, [id]);

  // const calculateAmount = (formData) => {
  //   const { fromDate, toDate, givenBalance } = formData;

  //   if (fromDate && toDate && givenBalance) {
  //     const from = new Date(fromDate);
  //     const to = new Date(toDate);
  //     console.log('snehan', givenBalance, from, to);

  //     const daysInMonth = new Date(from.getFullYear(), from.getMonth() + 1, 0).getDate();
  //     const daysBetween = Math.ceil((from - to) / (1000 * 60 * 60 * 24)) + 1;

  //     const dailyAmount = givenBalance / daysInMonth;
  //     const totalAmount = dailyAmount * daysBetween;
  //     setTotalAmount(totalAmount);
  //     console.log('Total Amount:', daysInMonth, daysBetween, dailyAmount, totalAmount);
  //   }
  // };


  const calculateAmount = (formData) => {
    const { fromDate, toDate, givenBalance } = formData;
  
    if (fromDate && toDate && givenBalance) {
      const from = new Date(fromDate);
      const to = new Date(toDate);
  
      console.log('Input values:', givenBalance, from, to);
  
      const daysInMonth = new Date(from.getFullYear(), from.getMonth() + 1, 0).getDate();
      const daysBetween = Math.ceil((from - to) / (1000 * 60 * 60 * 24)) + 1;
  
      // Ensure daysBetween is not negative
      if (daysBetween <= 0) {
        console.error("Invalid date range: 'toDate' must be after 'fromDate'.");
        return;
      }
  
      const dailyAmount = (givenBalance / daysInMonth).toFixed(2); // Format to 2 decimal places
      const totalAmount = (dailyAmount * daysBetween).toFixed(2); // Format to 2 decimal places
  
      setFormData((prevData) => ({
        ...prevData,
        totalAmount, // Update totalAmount in formData
      })); // Parse back to float for further use if needed
      console.log('Calculated Amounts:', {
        daysInMonth,
        daysBetween,
        dailyAmount,
        totalAmount,
      });
    }
  };
  
  const handlePercentageChange = (e) => {
    const value = e.target.value;
    setPercentage(value);
    const calculatedGivenBalance = (formData.investmentAmount * value) / 100;
    setFormData((prevData) => ({
      ...prevData,
      givenBalance: calculatedGivenBalance,
      givenPercentage: value, // Update givenPercentage in formData
    }));
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      await addDoc(collection(db, "investmentPayments"), {
        ...formData,
        memberId: id,
        timestamp: serverTimestamp(), // Add current date and time
      });
      toast.success("Payment data saved successfully!");
      navigate('/investmentpayment');
    } catch (error) {
      console.error("Error saving payment data: ", error);
      toast.error("Failed to save payment data.");
    } finally {
      setIsSubmitting(false);
    }
  };


  // Effect to check form validity
  useEffect(() => {
    validationSchema.isValid(formData).then((valid) => {
      setIsValid(valid);
    });
  }, [formData, validationSchema]);

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {/* Background Image */}
      <img
        src={front1}
        alt="Background"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1, // Places the image behind the content
        }}
      />
      <CssVarsProvider>
        <Box sx={{ padding: 2 }}>
          <Typography
            level="h3"
            style={{
              color: "#FFFFFF",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            }}
            fontWeight="lg"
          >
            LIVE ACCOUNT BALANCE
          </Typography>
          <Typography
            level="body-sm"
            style={{
              color: "#FFFFFF",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            }}
            my={2}
          >
            Live Account balance Details
          </Typography>
          <Sheet
            variant="outlined"
            sx={{
              padding: 3,
              borderRadius: "sm",
              backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white
              backdropFilter: "blur(10px)", // Optional: Adds a blur effect for the content behind
              boxShadow: "none",
            }}
          >
            {/* <Box sx={{ display: "flex", justifyContent: "flex-start", my: 2 }}>
              <Button
                type="submit"
                color="warning"
                variant="solid"
                sx={{ marginTop: 2 }}
                // onClick={handleViewRequest}
                onClick={handleViewRequest}
              >
                View Requests
              </Button>
            </Box> */}
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2">
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Payment Mode:</FormLabel>
                  <select
                    className="px-2 py-2 border rounded bg-transparent border-gray-600"
                    name="paymentModeId"
                    value={formData.paymentModeId}
                    onChange={handleInputChange}
                    required
                    style={{ background: "#1a2a3385", color: "white" }}
                  >
                    <option value="">Select Payment Mode</option>
                    {paymentTypes.map((type) => (
                      <option
                        key={type.paymentModeId}
                        value={type.paymentModeType}
                      >
                        {type.paymentModeType}
                      </option>
                    ))}
                  </select>
                  {errors.paymentModeId && (
                    <Typography color="error" variant="body2">
                      {errors.paymentModeId}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Select Image:</FormLabel>
                  <Input
                    type="file"
                    name="image"
                    onChange={handleInputChange}
                    className="px-2 py-2 border rounded bg-transparent border-gray-600"
                    style={{ background: "#1a2a3385", color: "white" }}
                  />
                  {errors.imageUrl && (
                    <Typography color="error">{errors.imageUrl}</Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Investment Amount (USDT):</FormLabel>
                  <Input
                    name="investmentAmount"
                    value={formData.investmentAmount}
                    // onChange={handleInputChange}
                    readOnly
                    type="number"
                    style={{ background: "#1a2a3385", color: "white" }}
                  />
                  {errors.investmentAmount && (
                    <Typography color="error" variant="body2">
                      {errors.investmentAmount}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Investment Date:</FormLabel>
                  <Input
                    name="investmentdate"
                    value={formData.investmentdate}
                    onChange={handleInputChange}
                    required
                    type="date"
                    style={{ background: "#1a2a3385", color: "white" }}
                  />
                  {errors.investmentAmount && (
                    <Typography color="error" variant="body2">
                      {errors.investmentAmount}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Broker Name:</FormLabel>
                  <input
                    type="text"
                    className="px-2 py-2 border rounded bg-transparent border-white-600"
                    name="brokerId"
                    value={formData.brokerId}
                    onChange={handleInputChange}
                    placeholder="Enter Broker Name"
                    required
                    style={{ background: "#1a2a3385", color: "white" }}
                  />
                  {errors.brokerId && (
                    <Typography color="error" variant="body2">
                      {errors.brokerId}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Password:</FormLabel>
                  <Input
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                    type="password"
                    style={{ background: "#1a2a3385", color: "white" }}
                  />
                  {errors.password && (
                    <Typography color="error" variant="body2">
                      {errors.password}
                    </Typography>
                  )}
                </FormControl>
                {/* <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>MT5 Id. (optional):</FormLabel>
                  <Input
                    name="md5Userid"
                    value={formData.md5Userid}
                    onChange={handleInputChange}
                    style={{ background: "#1a2a3385", color: "white" }}
                  />
                  {errors.mt5Id && (
                    <Typography color="error" variant="body2">
                      {errors.mt5Id}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>MT5 Password. (optional):</FormLabel>
                  <Input
                    name="mt5Password"
                    value={formData.mt5Password}
                    onChange={handleInputChange}
                    type="password"
                    style={{ background: "#1a2a3385", color: "white" }}
                  />
                  {errors.mt5Password && (
                    <Typography color="error" variant="body2">
                      {errors.mt5Password}
                    </Typography>
                  )}
                </FormControl> */}
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Percentage:</FormLabel>
                  <Input
                    name="percentage"
                    value={percentage}
                    onChange={handlePercentageChange}
                    required
                    type="number"
                    style={{ background: "#1a2a3385", color: "white" }}
                  />
                  {errors.mt5Id && (
                    <Typography color="error" variant="body2">
                      {errors.mt5Id}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Given Amount</FormLabel>
                  <Input
                    name="givenBalance"
                    value={formData.givenBalance}
                    readOnly
                    type="number"
                    style={{ background: "#1a2a3385", color: "white" }}
                  />
                  {errors.mt5Password && (
                    <Typography color="error" variant="body2">
                      {errors.mt5Password}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>To Date:</FormLabel>
                  <Input
                    name="toDate"
                    value={formData.toDate}
                    onChange={handleInputChange}
                    required
                    type="date"
                    style={{ background: "#1a2a3385", color: "white" }}
                  />
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>From Date:</FormLabel>
                  <Input
                    name="fromDate"
                    value={formData.fromDate}
                    onChange={handleInputChange}
                    required
                    type="date"
                    style={{ background: "#1a2a3385", color: "white" }}
                  />
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Total Amount:</FormLabel>
                  <Input
                    name="totalAmount"
                    value={formData.totalAmount}
                    readOnly
                    type="number"
                    style={{ background: "#1a2a3385", color: "white" }}
                  />
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>

                  <FormLabel>Type:</FormLabel>
                  <select
                    className="px-2 py-2 border rounded bg-transparent border-gray-600"
                    name="tModeType"
                    value={formData.tModeType}
                    onChange={handleInputChange}
                    required
                    style={{ background: "#1a2a3385", color: "white" }}
                  >
                    <option value="">Select Type</option>
                    {types.map((t) => (
                      <option key={t.typeModeId} value={t.tModeType}>
                        {t.tModeType}
                      </option>
                    ))}
                  </select>
                </FormControl>
              </div>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  color="primary"
                  variant="solid"
                  sx={{ marginTop: 2 }}
                  disabled={!isValid}
                >
                  Pay Now
                </Button>
              </Box>
            </form>
          </Sheet>
        </Box>
        <SupportSystem />
      </CssVarsProvider>
    </div>
  );
};

export default CreatePayment;